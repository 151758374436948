<template>
  <Navigation />
  <div class="h-screen flex">
    <internLaftNav actived="Dashboard" />
    <div class="flex flex-col gap-2 px-2 border overflow-y-auto">
      <div class="flex h-10 py-1 justify-left px-2 md:px-6 text-red-800">
        <div
          v-if="organizationdetaill != null"
          class="text-lg lg:text-2xl font-semibold capitalize"
        >
          {{ organizationdetaill.title }}
        </div>
      </div>

      <!-- over View part-1 -->
      <div class="flex flex-wrap w-full px-4 gap-8">
        <!-- 1st 4 container -->
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.TotalCourse }}
          </div>
          <div>Total Courses</div>
        </div>
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.PublishedCourse }}
          </div>
          <div>Published Courses</div>
        </div>
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.TotalInstructor }}
          </div>
          <div>Total Instructors</div>
        </div>
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.InvitePendingInstructor }}
          </div>
          <div>Instructors Inivitations Pending</div>
        </div>

        <!-- Last 4 -->

        <div
          @click="duplicateRecords_remove_in_object_list(this.studentInfo)"
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.TotalStudent }}
          </div>
          <div>Total Students</div>
        </div>
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.ActiveStudent }}
          </div>
          <div>Active Students</div>
        </div>

        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.InvitePendingStudent }}
          </div>
          <div>Students inivitations Pending</div>
        </div>
        <div
          class="border p-4 flex flex-col justify-center items-center rounded-lg shadow-md border-red-800 w-80"
        >
          <div class="h-1/2 font-bold text-2xl text-red-800">
            {{ this.dashboardinfo.UnpublishedCourse }}
          </div>
          <div>Unpublished Courses</div>
        </div>
      </div>
      <!-- over view part-2 -->
      <div class="flex flex-col md:flex-row gap-4 items-center m-4 p-4">
        <div>
          <h1 class="text-lg lg:text-2xl font-semibold capitalize">
            Course List
          </h1>
          <div class="flex flex-wrap gap-1">
            <template v-for="course in published_course" :key="course">
              <div
                class="relative border border-gray-300 mx-2 rounded-md flex flex-col items-center justify-center"
                style="width: 320px; height: 180px"
              >
                <h5 class="text-center">
                  {{ course.course.title }}
                </h5>
                <div>
                  <p>
                    Status :
                    <span
                      class="rounded-md"
                      :class="[
                        course.published == true
                          ? 'text-green-600'
                          : 'text-red-400',
                      ]"
                    >
                      {{ course.published == true ? "Active" : "Inactive" }}
                    </span>
                  </p>
                </div>
                <div class="absolute right-2 bottom-2">
                  <button
                    @click="activateTheCourse(course.course.id, true)"
                    v-if="course.published == false"
                    class="text-sm bg-green-400 rounded-md px-2 py-1 text-white"
                  >
                    Activate
                  </button>
                  <button
                    @click="activateTheCourse(course.course.id, false)"
                    v-else
                    class="text-sm bg-red-400 rounded-md px-2 py-1 text-white"
                  >
                    Deactivate
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue";
import internLaftNav from "@/views/organization/leftNavIntern.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      published_course: null,
      organizationdetaill: null,
      orgcoursedetails: null,
      instructorInfo: null,
      studentInfo: null,
      dashboardinfo: {
        TotalCourse: "",
        TotalStudent: "",
        TotalInstructor: "",
        InvitePendingStudent: "",
        PublishedCourse: "",
        UnpublishedCourse: "",
        ActiveStudent: "",
        InvitePendingInstructor: "",
      },
    };
  },
  computed: mapGetters(["userInfo"]),
  components: {
    Navigation,
    internLaftNav,
  },
  async created() {
    let token_id = this.userInfo.token_key;
    this.availableCourse();
    await axios
      .get(`${process.env.VUE_APP_API}/organization_detail/?Key=${token_id}`)
      .then((response) => {
        this.organizationdetaill = response.data.data;
      });

    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?organization_id=${this.organizationdetaill.id}`
      )
      .then((Response) => {
        this.orgcoursedetails = Response.data.data;
      });

    await axios
      .get(
        `${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`
      )
      .then((response) => (this.instructorInfo = response.data.data));

    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsdetaill/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.studentInfo = response.data.data;
        let uniqueRecords = [];
        let uniqueRecords_id = [];
        this.studentInfo.forEach((record) => {
          if (uniqueRecords_id.indexOf(record.students_email) === -1) {
            uniqueRecords.push(record);
            uniqueRecords_id.push(record.students_email);
          }
        });
        this.studentInfo = uniqueRecords;
      });
    this.dashboardinfo.TotalCourse = this.orgcoursedetails.length;
    this.dashboardinfo.PublishedCourse = this.orgcoursedetails.filter(
      (course) => course.is_published == true
    ).length;
    this.dashboardinfo.UnpublishedCourse = this.orgcoursedetails.filter(
      (course) => course.is_published == false
    ).length;
    this.dashboardinfo.TotalInstructor = this.instructorInfo.length;
    this.dashboardinfo.InvitePendingInstructor = this.instructorInfo.filter(
      (instructor) => instructor.published == false
    ).length;
    this.dashboardinfo.TotalStudent = this.studentInfo.length;
    this.dashboardinfo.ActiveStudent = this.studentInfo.filter(
      (student) => student.students_Status == true
    ).length;
    this.dashboardinfo.InvitePendingStudent = this.studentInfo.filter(
      (student) => student.students_Status == false
    ).length;
  },
  methods: {
    availableCourse() {
      axios
        .get(
          `${process.env.VUE_APP_API}/published/course/list/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.published_course = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activateTheCourse(id, status) {
      let json_data = {
        user: this.userInfo.user.id,
        course: id,
        published: status,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/published/course/update/${id}/?Key=${this.userInfo.token_key}`,
          json_data
        )
        .then((response) => {
          console.log(response);
          this.availableCourse();
        })
        .catch((error) => {
          console.log(error);
          this.availableCourse();
        });
    },
  },
};
</script>

<style scoped></style>
