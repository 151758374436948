<template>
    <div class="w-full h-full">
      <div class="accordion-collapse border-0 text-sm md:text-base">
        <div
          class="accordion-body py-4 pr-10 flex gap-3 justify-evenly items-start p-4"
        >
          <div class="flex flex-col gap-2 relative w-4/12">
            <div
              class="flex justify-between items-center p-1 rounded-lg shadow-lg border-b border-gray-100 bg-primary px-2"
            >
              <h4 class="m-0 text-lg text-white">Key Objectives</h4>
            </div>
            <ol v-if="key_object_list.length > 0">
              <li
                v-for="el in this.key_object_list"
                :key="el"
                class="list-disc"
              >
                <div class="flex justify-between">
                  {{ el.title }}
                </div>
              </li>
            </ol>
            <ol v-else>
              <li>No data</li>
            </ol>
          </div>
          <div class="w-8/12">
            <div
              class="flex justify-between items-center p-1 rounded-lg shadow-lg border-b border-gray-100 bg-primary px-2"
            >
              <h4 class="text-lg m-0 text-white">Touch-points</h4>
            </div>
            <ol v-if="touch_point_list.length > 0">
              <li v-for="el in this.touch_point_list" :key="el" class="list-disc">
                <div class="flex mt-3 gap-3 justify-between">
                  <div class="m-0 p-0">{{ el.title }}</div>
                  <div class="flex gap-2 items-center justify-between w-1/2">
                    <div class="flex items-center w-1/2 justify-start gap-2">
                        <img v-if="el.initiated" src="../assets/check_mark.png" alt="" class="h-6">
                        <img v-else src="../assets/wip.png" alt="" class="h-6">
                        <p class="p-0 m-0" id="touch-point-initiated">Initiated</p>
                    </div>
                    <div class="flex items-center w-1/2 justify-start gap-1">
                        <img v-if="el.completed" src="../assets/check_mark.png" alt="" class="h-6">
                        <img v-else src="../assets/wip.png" alt="" class="h-6">
                      <p class="p-0 m-0" for="touch-point-completed">Completed</p>
                    </div>
                </div>
                </div>
              </li>
            </ol>
            <ol v-else class="py-2">
              No data
            </ol>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        key_object_list: [],
        touch_point_list: [],
      };
    },
    props: ["course_id", "user_token", "user_id"],
  
    created() {
      this.keyObjectCall();
      this.touchPointCall();
    },
    methods: {
      async keyObjectCall() {
        await axios
          .get(
            `${process.env.VUE_APP_API}/Keyobjectives/list/${this.course_id}/?Key=${this.user_token}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.key_object_list = resp.data.data;
            } else {
              this.key_object_list = [];
            }
          });
      },
      async touchPointCall() {
        await axios
          .get(
            `${process.env.VUE_APP_API}/touchpoints/list/${this.course_id}/?Key=${this.user_token}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.touch_point_list = resp.data.data;
            } else {
              this.touch_point_list = [];
            }
          });
      },
    },
  };
  </script>
  
  <style></style>
  