<template>
<div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div v-if="this.isAuthenticated" class="flex w-full 2xl:max-w-7xl mx-auto">
    <LeftNavigationView actived="home" />
    <div class="w-full">
    <div class="wf-section py-8">
        <div class="container-default-1209px w-container">
            <div class="flex items-start justify-between">
                <div class="">
                    <h1 class="text-4xl">Welcome  {{ this.userInfo.user.display_name }} to <span class="text-4xl">your <span class="font-color-primary ">AARAMBH 2023</span></span></h1>
                    <p class="pl-4"> ...an Accelerated Learning Journey for Self, People & Business Leadership.</p>
                    <!-- Objective -->
    <div class="px-4 max-w-6xl mx-auto text-justify">
        <h3>Program Objectives</h3>
        <ol v-if="this.userInfo.user.category=='SLM'">
            <li class="list-disc text-gray-700">Understand how change impacts at a greater speed today, and how, having an enterprising approach helps you create opportunities for yourself and your sales teams & leaders</li>
            <li class="list-disc text-gray-700">Know and apply simple and essential business and financial aspects of your business, to make better and effective decisions based on data</li>
            <li class="list-disc text-gray-700">Design & drive business and people related strategies in a competitive business environment</li>
            <li class="list-disc text-gray-700">Know how to lead with or without formal authority, with greater understanding of your teams and situations</li>
            <li class="list-disc text-gray-700">Drive effective negotiation & influencing skills across the teams, and be an effective influencer yourself</li>
            <li class="list-disc text-gray-700">Know and identify the readiness levels of the people your interact with (team members, partners, clients) and know how to adapt to situations accordingly </li>
        </ol>
        <ol v-else-if="this.userInfo.user.category=='FLM'">
            <li class="list-disc text-gray-700">Understand the needs and expectations of the sales leader of today, and be ready with the right skill sets to meet these expectations</li>
            <li class="list-disc text-gray-700">Know your business at a deeper level, and make decisions with greater understanding of problems and solutions</li>
            <li class="list-disc text-gray-700">Identify sales opportunities for self and team, and be able to understand the basics of financial acumen from a market perspective</li>
            <li class="list-disc text-gray-700">Develop a more impactful version of yourself through personal and people excellence techniques</li>
            <li class="list-disc text-gray-700">Be a more effective communicator, especially during critical conversations, sales discussions and negotiations</li>
            <li class="list-disc text-gray-700">Understand your team better by identifying their individual readiness and maturity levels, to drive overall team performance</li>
        </ol>
        <ol v-else-if="this.userInfo.user.category=='IC'">
            <li class="list-disc text-gray-700">Understand softer aspects involved in value selling & gather deeper insights into the same</li>
            <li class="list-disc text-gray-700">Perform root-cause analysis and bring in a solution-oriented mindset towards day-to-day operations</li>
            <li class="list-disc text-gray-700">Identify sales opportunities & be able to understand the basics of financial acumen from a market perspective</li>
            <li class="list-disc text-gray-700">Build on execution excellence and apply the knowledge in the market</li>
            <li class="list-disc text-gray-700">Enhance the skills to build relationship</li>
            <li class="list-disc text-gray-700">Be able to understand the importance of perception - self & peers</li>
            <li class="list-disc text-gray-700">Manage time - be efficient & effective simultaneously</li>
        </ol>
    </div>
                </div>
                <div v-if="this.isAuthenticated" class="spacer hero z-20">
                    <img src="../assets/AARAMBH.jpeg" width="429" 
                    sizes="(max-width: 479px) 90vw, (max-width: 991px) 429px, (max-width: 1439px) 32vw, 429px" 
                    srcset="../assets/AARAMBH.jpeg 500w, ../assets/AARAMBH.jpeg 800w, ../../catalyft-ui-templates/images/home_hero_image.jpg 858w" 
                    alt="Course Teachers - Academy Webflow Template" class="image hero">
                </div>
            </div>
        </div>
    </div>

    <!-- buttons -->

    <!-- <div class="flex gap-8 justify-center py-4">
        <div>
            <router-link :to="{name:'UserProfile',params:{selectValue:'journey'}}">
                <button class="bg-primary px-4 py-1 rounded-lg">Your Learning Journey</button>
            </router-link>
        </div>
        <div>
            <router-link :to="{name:'UserProfile',params:{selectValue:'Courses'}}">
                <button class="bg-primary px-4 py-1 rounded-lg">Your Courses</button>
            </router-link>
        </div>
        <div>
            <router-link :to="{name:'UserProfile',params:{selectValue:'profile'}}">
                <button class="bg-primary px-4 py-1 rounded-lg">Profile</button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
            </router-link>
        </div>
        <div class="hidden">
            <router-link :to="{name:'Courses'}">
                <button class="bg-primary px-4 py-1 rounded-lg">Home</button>
            </router-link>
        </div>
    </div> -->
</div>    
</div>
    <Footer />
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters} from "vuex";
import LeftNavigationView from "@/components/LeftNav.vue";
import { provide } from '@vue/runtime-core';

export default {
    name: "Home",
    data() {
        return {
            messages: {
                text: "",
                type: 'null',
            },
            title : '',
            activeCourse : ''
        }
    },
    computed: mapGetters(['isAuthenticated','userInfo']),
    components: {
        Navigation,
        Footer,
        FlashMessage,
        LeftNavigationView,
    },

    created() {

        this.title = process.env.VUE_APP_Title
        if(this.isAuthenticated == true){
            axios.get(`${process.env.VUE_APP_API}/currentcoures/?Key=${this.userInfo.token_key}`)
            .then(resp=>{
                if(resp.data.status==200){
                    this.activeCourse = resp.data
                    console.log(this.activeCourse)
                }else{
                    console.log(resp)
                }
            }).catch((error)=>{
                console.log(error)
            })
        }
    },
    mounted() {
        
    },
    methods: {
    }
};
</script>

<style scoped>
.marquee_list {
    --marquee-width: 50vw;
    --marquee-height: 64;
    /* --marquee-elements: 12; */
    /* defined with JavaScript */
    --marquee-elements-displayed: 5;
    --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
    --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

.marquee {
    width: var(--marquee-width);
    height: var(--marquee-height);

    overflow: hidden;
    position: relative;
}

.marquee:before,
.marquee:after {
    position: absolute;
    top: 0;
    width: 10rem;
    height: 100%;
    content: "";
    z-index: 1;
}

.marquee:before {
    left: 0;
    background: linear-gradient(to right, rgb(255, 255, 255) 0%, transparent 100%);
}

.marquee:after {
    right: 0;
    background: linear-gradient(to left, rgb(255, 255, 255) 0%, transparent 100%);
}

.marquee-content {
    list-style: none;
    height: 100%;
    display: flex;
    gap: 6px;
    animation: scrolling var(--marquee-animation-duration) linear infinite;
}

/* .marquee-content:hover {
  animation-play-state: paused;
} */
@keyframes scrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
    }
}

.marquee-content img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: var(--marquee-element-width);
    max-height: 100%;
    white-space: nowrap;
}

.marquee-content img {
    width: 224px;
    /* height: 100%; */

}

@media (max-width: 850px) {

    .marquee_list {
        --marquee-width: 100vw;
        --marquee-height: 64px;
        --marquee-elements-displayed: 3;
    }

    .marquee:before,
    .marquee:after {
        width: 5rem;
    }
}
</style>
